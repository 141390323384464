<div>
    <!--<span class="k-button" (click)="refreshList()">
        <span class="k-icon k-i-refresh"></span>
    </span>
    <span class="k-button" (click)="showAddFilter()">
        <span class="k-icon k-i-plus"></span>
    </span>-->
    <div class="quick-filter">
        <button mat-icon-button (click)="refreshList()" matTooltip="Обновить">
            <mat-icon class="mat-24">refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="showAddFilter()" matTooltip="Фильтр">
            <mat-icon class="mat-24">add_circle_outline</mat-icon>
        </button>

        <!--<span class="k-button" (click)="showFromFileFilter()">
        <span class="k-icon k-i-download"></span>
        <input id="add-from-file-filter" type="file" style="display: none" />
    </span>-->

        <mat-form-field>
            <input matInput (keyup.enter)="refreshList()" [(ngModel)]="query" placeholder="Поиск">
            <mat-icon matSuffix (click)="refreshList()">search</mat-icon>
        </mat-form-field>

        <ng-content select="[quick-filter]"></ng-content>
    </div>

    <!--<span class="k-textbox k-space-right filter-search">
        <input type="text" id="icon-right" (keyup.enter)="refreshList()" [(ngModel)]="query">
        <button class="k-icon k-i-search" href="#" (click)="refreshList()">&nbsp;</button>
    </span>-->
    <!--<span>
        <input kendo-combobox="savedFilterElement" k-options="{{getSavedFilterOptions()}}" k-change="savedFilterChanged" />
    </span>
    <span class="k-button" *ngIf="filters.length" (click)="saveFilters()">
        <span class="k-icon k-i-tick"></span>
    </span>
    <span class="k-button" *ngIf="savedFilterSelected && displaySavedFilters" (click)="deleteCurrentSavedFilter()">
        <span class="k-icon k-i-cancel"></span>
    </span>-->
    <div *ngIf="filters.length" class="filters">
        <span class="filter-item" *ngFor="let filter of filters">
            <span class="filter-item__label">{{filter.DisplayName}}</span>
            <span class="filter-item__operation" (click)="displayOperation(filter)">{{getOperationName(filter.Operation)}}</span>
            <span class="filter-item__value" [hidden]="filter.ValueCount != 1" (click)="displayOperation(filter)">{{displayValue(filter)}}</span>
            <mat-icon (click)="removeFilter(filter)">clear</mat-icon>
        </span>
    </div>
</div>
<div class="filter-add" [hidden]="!displayAddFilter">
    <xrm-filter-tree [module]="module" [selectedFilter]="selectedFilter" (change)="onSelectFilter($event)"></xrm-filter-tree>

    <mat-form-field>
        <mat-select class="operation" [(value)]="selectedOperation" placeholder="Операция">
            <mat-option *ngFor="let option of possibleOperations" [value]="option.Id">{{option.Name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="fieldType == 1 && showInput()">
        <input matInput (keyup.enter)="applyAddFilter()" [(ngModel)]="fieldValue" type="number">
    </mat-form-field>
    <mat-form-field *ngIf="fieldType == 2 && showInput()">
        <input matInput (keyup.enter)="applyAddFilter()" [(ngModel)]="fieldValue" type="text">
    </mat-form-field>
    <mat-form-field *ngIf="fieldType == 4 && showInput()">
        <input matInput [matDatepicker]="picker" (keyup.enter)="applyAddFilter()" (dateChange)="onDatechange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>


    <!--<input [hidden]="fieldType != 1 || !showInput()" (keyup.enter)="applyAddFilter()" kendo-numeric-text-box [(ngModel)]="fieldValue" />
    <input [hidden]="fieldType != 2 || !showInput()" (keyup.enter)="applyAddFilter()" [(ngModel)]="fieldValue" type="text" class="k-textbox" />
    <input [hidden]="fieldType != 4 || !showInput()" (keyup.enter)="applyAddFilter()" kendo-date-picker [(ngModel)]="fieldValue" type="text" />
    <input [hidden]="fieldType != 5 || !showInput()" (keyup.enter)="applyAddFilter()" kendo-drop-down-list k-options="fieldValueOptions" [(ngModel)]="fieldValueDD" />-->

    <button mat-raised-button (click)="applyAddFilter()">Применить</button>
    <button mat-raised-button (click)="hideAddFilter()">Отменить</button>
</div>
