import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime } from 'rxjs/operators';
import { DetailInfo } from "../core/base-model";
import { AutocompleteFn } from "../core/common-helper";
import { ValueAccessorBase } from "./editor-models";

@Component({
    selector: 'chip-autocomplete',
    templateUrl: './chip-autocomplete.html',
    styles: [
        ':host { display: flex; width: 100%; }',
        'mat-form-field { width: 100%; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: ChipAutocompleteComponent, multi: true }
    ],
})
export class ChipAutocompleteComponent extends ValueAccessorBase<string[]> implements OnInit {
    separatorKeysCodes: number[] = [ENTER, COMMA];

    @Input() placeholder: string = '';
    @Input() fn: AutocompleteFn | null = null;
    @Input() details: Promise<DetailInfo[]> | null = null;

    @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
    control: UntypedFormControl = new UntypedFormControl();

    constructor() {
        super();
        this.innerValue = [];
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(500)
        )
            .subscribe(value => {
                if (typeof (value) == "string") {
                    this.updateDetails(value);
                }
            });
        this.updateDetails(null);
    }

    optionSelected(e: MatAutocompleteSelectedEvent): void {
        this.value.push(e.option.viewValue);
        this.chipInput.nativeElement.value = '';
        this.control.setValue(null);
        this.valueUpdated();
    }

    remove(val: string) {
        const index = this.value.indexOf(val);

        if (index >= 0) {
            this.value.splice(index, 1);
            this.valueUpdated();
        }
    }

    add(e: MatChipInputEvent): void {
        const val = (e.value || '').trim();

        if (this.value == null)
            this.value = [];

        if (val && !this.value.includes(val)) {
            this.value.push(val);
            this.valueUpdated();
        }

        // Clear the input value
        e.chipInput!.clear();

        this.control.setValue(null);
    }

    private valueUpdated() {
        this.value = [...this.value];
    }

    private updateDetails(query: string | null): void {
        if (this.fn)
            this.details = this.fn(query || '');
    }
}
