import { Component, Input } from "@angular/core";
import { UntypedFormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ErrorStateMatcher, MAT_DATE_FORMATS } from "@angular/material/core";
import { ValueAccessorBase } from "./editor-models";

const DATE_FORMATS = {
    parse: {
        dateInput: 'DD.MMM',
    },
    display: {
        dateInput: 'DD.MMM',
        monthYearLabel: 'MMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

const DEFAULT_YEAR = 2020;
const START_DAY = +new Date(DEFAULT_YEAR, 0, 1);
const MULTY = 1000 * 3600 * 24;

@Component({
    selector: 'inline-date-month',
    templateUrl: './inine-date-month.html',
    styles: [
        '.editor-text { min-height: 15px;}',
        '.editor-field { width: 100%; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: InlineDateMonthComponent, multi: true },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
    ],
})
export class InlineDateMonthComponent extends ValueAccessorBase<number | null> {
    @Input() placeholder: string | null = null;
    @Input() set disabled(value: boolean) {
        value ? this.control.disable() : this.control.enable();
    }
    @Input() errors: string[] = [];
    matcher: ErrorStateMatcher;
    control: UntypedFormControl = new UntypedFormControl();

    constructor() {
        super();
        this.matcher = {
            isErrorState: (control: UntypedFormControl | null): boolean => {
                return this.errors && this.errors.length > 0;
            }
        };

        this.control.valueChanges.subscribe(value => {
            this.value = this.toInt(value);
        });
        this.control.enable();
    }

    writeValue(value: number | null): void {
        super.writeValue(value);
        this.onWriteValue();
    }

    private onWriteValue(): void {
        let val: any = '';
        if (this.value != null)
            val = this.toDate(this.value);
        this.control.setValue(val, {
            emitEvent: false
        });
    }

    private toInt(value): number | null {
        const date = new Date(value) || null;
        if (!date)
            return null;

        date.setFullYear(DEFAULT_YEAR);

        return Math.floor((+date - START_DAY) / MULTY);
    }

    private toDate(value: number | null): Date | null {
        if (value == null)
            return null;

        return new Date(START_DAY + value * MULTY);
    }
}
