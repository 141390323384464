import { Injectable } from "@angular/core";

import { HttpService } from "./http-service";
import { PermissionService } from "../permissions/permissions";

@Injectable()
export class CallService {
    constructor(private http: HttpService,
        private permissionService: PermissionService) { }

    makeCall(phone: string): Promise<void> {
        if (!phone) {
            return;
        }

        if (this.permissionService.isInState('callcenter.MakeCall'))
            return this.http.post('/api/CallCenter/MakeCall', { to: phone });
    }
}
