import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { NgModule } from "@angular/core";

import { PermissionsActivate } from "./shared/permissions/permissions-activate";
import { ComponentHome } from "./components/home/component-home";
import { ComponentSidenav } from "./components/sidenav/component-sidenav";
import { ComponentLogin } from "./components/login/component-login";

const routes: Routes = [
    {
        path: '',
        component: ComponentSidenav,
        children: [
            { path: '', component: ComponentHome, pathMatch: 'full' },
            {
                path: 'product',
                loadChildren: () => import('./components/product/product-module').then(m => m.ProductModule)
            },
            {
                path: 'sale',
                loadChildren: () => import('./components/sale/sale.module').then(m => m.SaleModule)
            },
            {
                path: 'contact',
                loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule)
            },
            {
                path: 'contragent',
                loadChildren: () => import('./components/contragent/contragent.module').then(m => m.ContragentModule)
            },
            {
                path: 'lead',
                loadChildren: () => import('./components/lead/lead.module').then(m => m.LeadModule)
            },
            {
                path: 'promocode',
                loadChildren: () => import('./components/promocode/promocode.module').then(m => m.PromoCodeModule)
            },
            {
                path: 'comment',
                loadChildren: () => import('./components/comment/comment.module').then(m => m.CommentModule)
            },
            {
                path: 'account',
                loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'store',
                loadChildren: () => import('./components/store/store.module').then(m => m.StoreModule)
            },
            {
                path: 'stuff',
                loadChildren: () => import('./components/stuff/stuff.module').then(m => m.StuffModule)
            },
            {
                path: 'stuffBalance',
                loadChildren: () => import('./components/stuff-balance/stuff-balance.module').then(m => m.StuffBalanceModule)
            },
            {
                path: 'stuffDelivery',
                loadChildren: () => import('./components/stuff-delivery/stuff-delivery.module').then(m => m.StuffDeliveryModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'location',
                loadChildren: () => import('./components/location/location.module').then(m => m.LocationModule)
            },
            {
                path: 'chat',
                loadChildren: () => import('./components/chat/chat.module').then(m => m.ChatModule)
            }
        ]
    },
    { path: 'login', component: ComponentLogin, pathMatch: 'full' },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [PermissionsActivate]
})
export class AppRoutingModule { }
