import { Injectable } from '@angular/core';

import { HttpService } from '../../shared/core/http-service';
import { PermissionService, AccountPermission } from '../../shared/permissions/permissions';
import { LoginViewModel } from '../../shared/core/models/login.model';

@Injectable()
export class ProfileService {
    constructor(private httpService: HttpService,
        private permissionService: PermissionService) { }

    login(model: LoginViewModel): Promise<any> {
        return this.httpService.post('/api/profile/login', model);
    }

    updatePermissions(): Promise<void> {
        return this.permissionService.loadModules();
    }
}
