export class SaveField {
    constructor(public field: string, public value: any) { }
}

export class SaveModel {
    constructor(public id: string,
        public values: SaveField[]) {
    }
}

export class DetailInfoT<T> {
    static create<T>(val: DetailInfoT<T>): DetailInfoT<T> | null {
        if (val == null)
            return null;

        const result = new DetailInfoT<T>();
        result.Id = val.Id;
        result.Name = val.Name;
        return result;
    }

    static equals<T>(one: DetailInfoT<T> | null, two: DetailInfoT<T> | null): boolean {
        if (one == null && two == null) {
            return true;
        }
        if (one == null || two == null) {
            return false;
        }
        return one.Id == two.Id;
    }

    Id: T;
    Name: string = '';
}

export class DetailInfo extends DetailInfoT<string>
{
}
