import { Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { ValueAccessorBase } from "./editor-models";
import { DetailInfoT } from "../core/base-model";

@Component({
    selector: 'inline-enum',
    templateUrl: './inline-enum.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: InlineEnumComponent, multi: true }
    ],
})
export class InlineEnumComponent extends ValueAccessorBase<number> {
    @Input() placeholder: string | null = null;
    @Input() disabled: boolean = false;
    @Input() errors: string[] = [];
    @Input() required: boolean = false;
    @Input() details: Promise<DetailInfoT<number>[]> | null = null;

    detailValue: DetailInfoT<number> | null;

    constructor() {
        super();
    }

    private onWriteValue(): void {
        if (this.details) {
            this.details.then(details => {
                if (this.value != null) {
                    for (let det of details) {
                        if (det.Id == this.value) {
                            this.detailValue = det;
                        }
                    }
                }
                else {
                    this.detailValue = null;
                }
            });
        }
    }

    writeValue(value: number): void {
        super.writeValue(value);
        this.onWriteValue();
    }

    onChange(value: DetailInfoT<number>): void {
        if (value) {
            this.value = value.Id;
        }
    }
}
