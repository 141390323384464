import { Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { ValueAccessorBase } from "./editor-models";

@Component({
    selector: 'checkbox',
    templateUrl: './checkbox.html',
    styles: [
        ':host { width: 100%; }',
        '.details-checkbox { padding-bottom: 1.25em; }'
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: CheckboxComponent, multi: true }
    ],
})
export class CheckboxComponent extends ValueAccessorBase<boolean> {
    @Input() placeholder: string | null = null;
    @Input() disabled = false;
}
