<div class="details-actions">
    <ng-content select="[details-actions]"></ng-content>
</div>
<div class="details-wrapper">
    <div class="details-editor">
        <ng-content select="[details-editor]"></ng-content>
    </div>
    <div class="details-component">
        <ng-content select="[details-components]"></ng-content>
    </div>
</div>
