import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { PermissionService } from './permissions';

@Injectable()
export class PermissionsActivate implements CanActivate {
    constructor(private permissionService: PermissionService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return this.permissionService.isAuthenticated()
            .then(resp => {
                if (!resp) {
                    console.log('PermissionsActivate', resp);
                    this.router.navigateByUrl('/login');
                }

                const permissions: any = route.data ? route.data['permission'].split(';') : [];
                for (const permission of permissions) {
                    const response = this.permissionService.isInState(permission);
                    if (!response) {
                        console.log(`Fail to navigate to '${route.url}' with permission '${permission}'`);
                        return false;
                    }
                }
                return true;
            });
    }
}

