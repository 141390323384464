import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigurationService } from './configuration.service';
import { StorageService } from './storage.service';
import { LoginViewModel } from './models/login.model';

import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SecurityService {
    private headers: HttpHeaders;
    private storage: StorageService;
    private authenticationSource = new Subject<boolean>();
    authenticationChallenge$ = this.authenticationSource.asObservable();
    private refreshPromise: Promise<void> | null = null;
    private logoutInterval;

    constructor(private _http: HttpClient,
        private _router: Router,
        private _configurationService: ConfigurationService,
        _storageService: StorageService) {

        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        this.storage = _storageService;

        if (this.storage.retrieve('IsAuthorized') !== '') {
            this.IsAuthorized = this.storage.retrieve('IsAuthorized');
            this.authenticationSource.next(this.IsAuthorized);
            this.UserData = this.storage.retrieve('userData');
        }

        if (this.IsAuthorized) {
            this.setLogoutInterval();
        }
    }

    public IsAuthorized: boolean = false;

    public GetToken(): Promise<string> {
        let token = this.storage.retrieve('authorizationData');
        if (!token) {
            return Promise.resolve(token);
        }

        let expired = new Date(this.storage.retrieve('expired_at'));
        let now = new Date();
        if (expired < now) {
            return this.refreshToken()
                .catch(() => this.Logoff())
                .then(() => {
                    return this.storage.retrieve('authorizationData');
                });
        }

        return Promise.resolve(token);
    }

    private refreshToken(): Promise<void> {
        let model = {
            refreshToken: this.storage.retrieve('authorizationRefreshToken')
        };

        if (!this.refreshPromise) {
            this.refreshPromise = this._configurationService.getSettings().then(settings => {
                return this._http.post(settings.PersonalUrl + '/api/profile/refresh', JSON.stringify(model), {
                    headers: this.headers
                }).toPromise()
            })
                .then(resp => this.SetAuthorizationData(resp))
                .then(() => this.refreshPromise = null);
        }

        return this.refreshPromise;
    }

    public ResetAuthorizationData() {
        this.storage.store('authorizationData', '');
        this.storage.store('authorizationRefreshToken', '');

        this.IsAuthorized = false;
        this.storage.store('IsAuthorized', false);
    }

    public UserData: any;
    public SetAuthorizationData(data: any): void {
        if (this.storage.retrieve('authorizationData') !== '') {
            this.storage.store('authorizationData', '');
        }

        let now = new Date();
        now.setSeconds(now.getSeconds() + data.expires_in);

        this.storage.store('authorizationData', data.access_token);
        this.storage.store('authorizationRefreshToken', data.refresh_token);
        this.storage.store('expired_at', now.getTime());
        this.IsAuthorized = true;
        this.storage.store('IsAuthorized', true);

        this.setLogoutInterval();
    }

    public Authorize(model: LoginViewModel): Promise<void> {
        this.ResetAuthorizationData();
        return this.setHeaders()
            .then(() => this._configurationService.getSettings())
            .then(settings => {
                return this._http.post(settings.PersonalUrl + '/api/profile/login', JSON.stringify(model), {
                    headers: this.headers
                }).toPromise();
            })
            .then(resp => this.SetAuthorizationData(resp))
            .then(() => this.authenticationSource.next(this.IsAuthorized));
    }

    public Logoff() {
        let model = {
            refreshToken: this.storage.retrieve('authorizationRefreshToken')
        };

        this._configurationService.getSettings()
            .then(settings => {
                this._http.post(settings.PersonalUrl + '/api/profile/logout', JSON.stringify(model), {
                    headers: this.headers
                }).subscribe();
            });

        this.ResetAuthorizationData();

        // emit observable
        this.authenticationSource.next(false);
        this._router.navigateByUrl("/login");
        clearTimeout(this.logoutInterval);
    }

    private setHeaders(): Promise<void> {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });

        return this.GetToken()
            .then(token => {
                this.headers = this.headers.append('Authorization', 'Bearer ' + token);
            });
    }

    private setLogoutInterval(): void {
        clearTimeout(this.logoutInterval);

        let now = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        const interval = tomorrow.getTime() - now.getTime();
        this.logoutInterval = setTimeout(() => this.Logoff(), interval);
    }
}
