import { Component, Input, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { ValueAccessorBase } from "./editor-models";
import { DetailInfo } from "../core/base-model";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.html',
    styles: [
        ':host { width: 100%; }',
        '.editor-field { width: 100%; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: DropdownComponent, multi: true }
    ],
})
export class DropdownComponent extends ValueAccessorBase<DetailInfo> {
    @ViewChild('input') input: MatSelect;

    @Input() details: Promise<DetailInfo[]> | null = null;
    @Input() placeholder: string = '';
    @Input() required = true;
    @Input() disabled = false;

    compare(a: DetailInfo, b: DetailInfo): boolean {
        return DetailInfo.equals(a, b);
    }

    focus() {
        this.input.focus();
    }
}
