import { Component, ContentChild, Input, OnInit, TemplateRef } from "@angular/core";
import { DetailExpandService } from "../../core/detail.expand.service";

@Component({
    selector: 'details-container',
    templateUrl: './details-container.html',
    styleUrls: ['./details-container.scss']
})
export class DetailsContainerComponent implements OnInit {
    @ContentChild(TemplateRef) detailContentRef;

    @Input() expandKey: string;
    @Input() expanded = false;
    @Input() loading = false;

    loaded = false;

    constructor(private expandService: DetailExpandService) { }

    ngOnInit(): void {
        if (this.expandKey) {
            this.expanded = this.expandService.get(this.expandKey);
        }

        this.loaded = this.expanded;
    }

    toggleExpand() {
        this.expanded = !this.expanded;
        this.loaded = this.loaded || this.expanded;
        if (this.expandKey) {
            this.expandService.set(this.expandKey, this.expanded);
        }
    }
}
