<nav class="navbar-header">
    <a [routerLink]="['/']">XRM</a>
    <span *ngIf="IsAuthorized" class="login">{{account?.Name}}</span>
    <button *ngIf="IsAuthorized" mat-icon-button [matMenuTriggerFor]="userMenu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
        <button (click)="changePassword()" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Изменить пароль</span>
        </button>
        <button (click)="logout()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            <span>Выход</span>
        </button>
    </mat-menu>

    <span class="title">{{title}}</span>

    <button *ngIf="IsAuthorized && enableSpeech && runningSpeech" mat-icon-button (click)="stopSpeech()">
        <mat-icon>record_voice_over</mat-icon>
    </button>
    <button *ngIf="IsAuthorized && enableSpeech && !runningSpeech" mat-icon-button (click)="startSpeech()">
        <mat-icon>voice_over_off</mat-icon>
    </button>
</nav>
