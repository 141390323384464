import { Injectable } from '@angular/core';

import { TitleService } from '../core/title-service';
import { HttpService } from './http-service';

@Injectable()
export class GridService {
    constructor(public titleService: TitleService,
        private http: HttpService) { }

    list<T>(module: string, filter: ClientFilter): Promise<ItemList<T>> {
        return this.http.post(`/api/${module}/List`, filter);
    }

    getTree(module: string): Promise<any> {
        return this.http.post(`/api/${module}group/tree`, null);
    }

    execute(module: string, action: string, model: any = null): Promise<any> {
        return this.http.post(`/api/${module}/${action}`, model);
    }
}

export class ItemList<T>{
    Items: T[] = [];
    TotalCount: number = 0;
    OrderBy: string = '';
    OrderDirection: EOrderDirection = EOrderDirection.ASC;
}

export enum EOrderDirection {
    ASC,
    DESC
}

export enum EOperation {
    Like = 1,
    Contains = 2,
    IsEmpty = 3,
    IsNotEmpty = 4,
    Lt = 7,
    Gt = 8,
    NotEqual = 9,
    LtOrEqual = 10,
    GtOrEqual = 11,
    Equal = 12,
    NotContains = 13,
    StartsWith = 14,
    EndWith = 15,
}

export interface FilterMap {
    name?: string;
    filterFn?: {
        (val: any): any
    };
}

export class ClientFilter {
    Take: number = 0;
    Skip: number = 0;
    Query: string;
    Filters: Filter[] = [];
    OrderBy: string = '';
    OrderDirection: EOrderDirection = EOrderDirection.ASC;

    constructor(options: DxLoadOptions,
        private map?: Map<string, FilterMap>) {
        if (!options)
            return;

        this.Take = options.take || 0;
        this.Skip = options.skip || 0;

        if (options.sort && options.sort.length) {
            this.OrderBy = options.sort[0].selector;
            this.OrderDirection = options.sort[0].desc ? EOrderDirection.DESC : EOrderDirection.ASC;
        }

        if (options.filter) {
            this.Filters = [this.parseFilter(options.filter)].filter(f => f);
        }
    }

    private parseFilter(dxFilter: any[]): Filter {
        const filter = new Filter();

        if (!dxFilter.length)
            return null;

        if (dxFilter.length < 3)
            throw dxFilter;

        // simple
        if (typeof (dxFilter[0]) === 'string') {
            filter.Property = this.getProperty(dxFilter[0]);
            filter.Operation = getFilter(dxFilter[1]);
            filter.Value = this.getValue(dxFilter[0], dxFilter[2]);
            return filter;
        }

        const operation = dxFilter[1];
        const filters: Filter[] = [];

        switch (operation) {
            case "and":
                filter.And = filters;
                break;
            case "or":
                filter.Or = filters;
                break;
            default:
                throw dxFilter;
        }

        for (let i = 0; i < dxFilter.length; i += 2) {
            filters.push(this.parseFilter(dxFilter[i]));
        }

        return filter;
    }

    private getProperty(property: string): string {
        return this.map?.get(property)?.name ?? property;
    }

    private getValue(property: string, val: any): any {
        const fn = this.map?.get(property)?.filterFn;
        if (!fn)
            return val;
        return fn(val);
    }
}

export class Filter {
    Operation: EOperation = EOperation.Contains;
    Property: string = '';
    Value: string | number = '';
    Or?: Filter[] = null;
    And?: Filter[] = null;
}

export interface DxLoadOptions {
    skip?: number;
    take?: number;
    sort?: DxLoadSortOptions[];
    filter?: Array<any>;
}

export interface DxLoadSortOptions {
    selector: string;
    desc: boolean;
}

type DxFilterOperator = "=" | "<>" | ">" | ">=" | "<" | "<=" | "startswith" | "endswith" | "contains" | "notcontains";

function getFilter(operator: DxFilterOperator): EOperation {
    switch (operator) {
        case "=":
            return EOperation.Equal;
        case "<>":
            return EOperation.NotEqual;
        case ">":
            return EOperation.Gt;
        case ">=":
            return EOperation.GtOrEqual;
        case "<":
            return EOperation.Lt;
        case "<=":
            return EOperation.LtOrEqual;
        case "startswith":
            return EOperation.StartsWith;
        case "endswith":
            return EOperation.EndWith;
        case "contains":
            return EOperation.Contains;
        case "notcontains":
            return EOperation.NotContains;
    }

    return null;
}
