<mat-form-field class="editor-field">
    <input [ngModel]="value" type="text" [errorStateMatcher]="matcher" placeholder="{{placeholder}}" matInput [formControl]="control" [matAutocomplete]="auto" (blur)="onBlur()" [required]="required">
    <mat-error *ngFor="let error of errors">{{error}}</mat-error>
    <button mat-button *ngIf="value" matSuffix mat-icon-button (click)="value = null">
        <mat-icon>close</mat-icon>
    </button>
    <button mat-button *ngIf="suffixIcon" matSuffix mat-icon-button color="primary" (click)="execSuffixAction()">
        <mat-icon>{{suffixIcon}}</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
        <mat-option *ngFor="let option of details | async" [value]="option">
            <div>{{ option.Name }}</div>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
