import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import * as Sentry from "@sentry/browser";

import { AppComponent } from './components/app/app.component';
import { AppModuleShared } from './app.shared.module';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }
    handleError(error) {
        Sentry.captureException(error.originalError || error);
        console.error(error);
    }
}

export function provideErrorHandler() {
    if (isDevMode())
        return new ErrorHandler();

    Sentry.init({
        dsn: "https://c7983e46b8fc400bb8914f451ae95501@sentry.io/1515027"
    });
    return new SentryErrorHandler();
}

@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        AppModuleShared
    ],
    providers: [
        {
            provide: ErrorHandler,
            useFactory: provideErrorHandler
        },
        {
            provide: 'BASE_URL',
            useFactory: getBaseUrl
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

registerLocaleData(localeRu);
