import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { HttpService } from "../core/http-service";

class ChangePassword {
    CurrentPassword: string = null;
    Password: string = null;
}

@Component({
    selector: 'change-password-dialog',
    templateUrl: 'change-password.html',
})
export class ChangePasswordComponent {
    model = new ChangePassword();
    errors: string[] = [];

    constructor(private dialogRef: MatDialogRef<ChangePasswordComponent>,
        private httpService: HttpService) { }

    onChangePassword() {
        this.httpService.post(`/api/Profile/ChangePassword`, this.model)
            .then(() => this.dialogRef.close())
            .catch(() => this.errors = ['Неверный пароль']);
    }
}
