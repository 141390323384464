import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { RouterModule } from "@angular/router";
import { RemoveDialogComponent } from "../dialogs/remove-dialog/remove-dialog.component";
import { HistoryComponent } from "../history/history.component";
import { PermissionIfDirective } from "../permissions/permission-directive";
import { AutocompleteComponent } from "./autocomplete";
import { CheckboxComponent } from "./checkbox";
import { ChipAutocompleteComponent } from "./chip-autocomplete";
import { DateRangeComponent } from "./date-range";
import { DateTimeComponent } from "./date-time";
import { DetailsContainerComponent } from "./details-container/details-container";
import { DetailsEditorComponent } from "./details/details-editor";
import { DropdownComponent } from "./dropdown";
import { DropdownStringComponent } from "./dropdown-string";
import { InlineDateComponent } from "./inline-date";
import { InlineDateMonthComponent } from "./inline-date-month";
import { InlineEnumComponent } from "./inline-enum";
import { InlineNumberComponent } from "./inline-number";
import { InlinePasswordComponent } from "./inline-password";
import { InlineTextComponent } from "./inline-text";
import { MultylineTextComponent } from "./multyline-text";
import { PhonePipe } from "./pipe/phone-pipe";

@NgModule({
    imports: [
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatDividerModule,
        MatSortModule,
        MatChipsModule,
        MatProgressBarModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
    ],
    exports: [
        InlineTextComponent,
        MultylineTextComponent,
        DetailsEditorComponent,
        CheckboxComponent,
        InlineNumberComponent,
        DropdownComponent,
        DropdownStringComponent,
        AutocompleteComponent,
        DetailsContainerComponent,
        PermissionIfDirective,
        InlinePasswordComponent,
        InlineDateComponent,
        InlineDateMonthComponent,
        RemoveDialogComponent,
        InlineEnumComponent,
        DateTimeComponent,
        DateRangeComponent,
        HistoryComponent,
        ChipAutocompleteComponent,
        PhonePipe,
    ],
    declarations: [
        InlineTextComponent,
        MultylineTextComponent,
        DetailsEditorComponent,
        CheckboxComponent,
        InlineNumberComponent,
        DropdownComponent,
        DropdownStringComponent,
        AutocompleteComponent,
        DetailsContainerComponent,
        PermissionIfDirective,
        InlinePasswordComponent,
        InlineDateComponent,
        InlineDateMonthComponent,
        RemoveDialogComponent,
        InlineEnumComponent,
        DateTimeComponent,
        DateRangeComponent,
        HistoryComponent,
        ChipAutocompleteComponent,
        PhonePipe,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ]
})
export class EditorModule { }
