import { Component, NgModule, OnInit, ViewEncapsulation } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SignalRService } from '../../shared/core/signalr.service';
import { ModuleItem, PermissionService } from '../../shared/permissions/permissions';

@Component({
    selector: 'xrm-sidenav',
    templateUrl: './component-sidenav.html',
    styleUrls: ['./component-sidenav.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ComponentSidenav implements OnInit{
    modules: ModuleItem[] = [];
    activeModule?: ModuleItem = null;

    constructor(private permissionService: PermissionService,
        private router: Router,
        private signalrService: SignalRService) { }

    ngOnInit() {
        this.loadModules();
        this.signalrService.permissionChanged.subscribe(() => this.loadModules());

        this.router.events
            .subscribe(val => {
                if (val instanceof NavigationEnd) {
                    this.onNavigated();
                }
            });

        this.onNavigated();
    }

    private onNavigated() {
        if (!this.modules)
            return;

        const url = this.router.url;
        const modules = [...this.modules].sort().reverse();
        this.activeModule = modules.find(m => url.startsWith(m.Url));
    }

    loadModules(): void {
        this.permissionService.getModules()
            .then(resp => this.modules = resp || [])
            .then(() => this.onNavigated());
    }
}

@NgModule({
    imports: [
        MatSidenavModule,
        MatListModule,
        RouterModule,
        BrowserAnimationsModule
    ],
    exports: [ComponentSidenav],
    declarations: [ComponentSidenav],
    providers: [],
})
export class ComponentSidenavModule { }
