import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './components/app/app.component';
import { ComponentSidenavModule } from './components/sidenav/component-sidenav';
import { ComponentLoginModule } from './components/login/component-login';
import { SnakBarModule } from './shared/snackbar/snackbar.module';
import { BaseModule } from './shared/core/base.module';

import { NavBarModule } from './shared/navbar/navbar';
import { AppRoutingModule } from './app.routing.module';
import { ComponentHomeModule } from './components/home/component-home';
import { HttpClientModule } from '@angular/common/http';

import { SecurityService } from './shared/core/security.service';
import { ConfigurationService } from './shared/core/configuration.service';
import { StorageService } from './shared/core/storage.service';
import { HttpService } from './shared/core/http-service';
import { TitleService } from './shared/core/title-service';
import { PermissionService } from './shared/permissions/permissions';
import { SignalRService } from './shared/core/signalr.service';
import { CallService } from './shared/core/call.service';
import { DetailExpandService } from './shared/core/detail.expand.service';
import { SpeechRecognisionService } from './shared/core/speech.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        NavBarModule,
        AppRoutingModule,
        SnakBarModule,
        BaseModule,

        ComponentSidenavModule,
        ComponentLoginModule,
        ComponentHomeModule,
    ],
    providers: [
        // deprecated
        HttpService,
        PermissionService,

        CallService,
        SecurityService,
        ConfigurationService,
        StorageService,
        SignalRService,
        DetailExpandService,
        SpeechRecognisionService,
        
        TitleService,
        { provide: LOCALE_ID, useValue: 'ru-RU' },
    ]
})
export class AppModuleShared {
}
