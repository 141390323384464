import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

import { GridService, ClientFilter, Filter, EOperation } from "../../core/grid-service";
import { ContactListItem } from "../../../components/contact/contact.model";
import { LeadListItem } from "../../../components/lead/lead.model";

@Component({
    selector: 'snackbar-call',
    templateUrl: './snackbar-call.component.html',
    styleUrls: ['./snackbar-call.component.scss'],
    providers: [
        GridService
    ]
})
export class SnackBarCallComponent {
    contacts: ContactListItem[] = [];
    leads: LeadListItem[] = [];

    constructor(@Inject(MAT_SNACK_BAR_DATA) public phone: string,
        private gridService: GridService) {

        this.initContacts();
        this.initLeads();
    }

    private initContacts() {
        let filter: ClientFilter = new ClientFilter({
            take: 10
        });

        let clientFilter: Filter = new Filter();
        clientFilter.Operation = EOperation.Equal;
        clientFilter.Property = "Communications.Name";
        clientFilter.Value = this.phone;

        filter.Filters.push(clientFilter)

        this.gridService.list<ContactListItem>('contact', filter)
            .then(resp => this.contacts = resp.Items);
    }

    private initLeads() {
        let filter: ClientFilter = new ClientFilter({
            take: 10
        });

        let clientFilter: Filter = new Filter();
        clientFilter.Operation = EOperation.Equal;
        clientFilter.Property = "Phone";
        clientFilter.Value = this.phone;

        filter.Filters.push(clientFilter)

        this.gridService.list<LeadListItem>('lead', filter)
            .then(resp => this.leads = resp.Items);
    }
}
