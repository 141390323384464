import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    transform(value: string | null): string | null {
        if (!value || value.length != 11) {
            return value;
        }

        return value.replace(/([7])([0-9]{3})([0-9]{3})([0-9]{4})/, '+$1 ($2) $3-$4');
    }
}
