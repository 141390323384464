import { Component, Input } from "@angular/core";
import { UntypedFormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ValueAccessorBase } from "./editor-models";

@Component({
    selector: 'inline-password',
    templateUrl: './inline-password.html',
    styles: [
        '.editor-text { min-height: 1.125em;}',
        '.editor-field { width: 100%; }'
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: InlinePasswordComponent, multi: true }
    ],
})
export class InlinePasswordComponent extends ValueAccessorBase<string> {
    @Input() placeholder: string | null = null;
    @Input() disabled = false;
    @Input() errors: string[] = [];
    matcher: ErrorStateMatcher;

    constructor() {
        super();
        this.matcher = {
            isErrorState: (control: UntypedFormControl | null): boolean => {
                return this.errors && this.errors.length > 0;
            }
        };
    }
}
