<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav class="sidenav"
                [opened]="true"
                [mode]="'side'">
        <mat-list>
            <mat-list-item class="sidenav-list-item" *ngFor="let module of modules">
                <a [routerLink]="[module.Url]" [ngClass]="{ 'active': module == activeModule }">{{module.Name}}</a>
            </mat-list-item>
        </mat-list>
    </mat-sidenav>
    <div class="sidenav-content">
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>
