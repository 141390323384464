import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { IConfiguration } from './models/configuration.model';

@Injectable()
export class ConfigurationService {
    serverSettings: IConfiguration = { PersonalUrl: "", HubUrl: "" };
    // observable that is fired when settings are loaded from server
    private settingsLoadedSource = new Subject();
    settingsLoaded$ = this.settingsLoadedSource.asObservable();
    isReady: boolean = false;

    constructor(private http: HttpClient) { }

    load() {
        const baseURI = (document.baseURI || "").endsWith('/') ? document.baseURI : `${document.baseURI}/`;
        let url = `${baseURI}Home/Configuration`;
        this.http.get<IConfiguration>(url).subscribe((json) => {
            this.serverSettings = json;
            this.isReady = true;
            this.settingsLoadedSource.next();
        });
    }

    public getSettings(): Promise<IConfiguration> {
        return this.executeLoaded(() => Promise.resolve(this.serverSettings));
    }

    public executeLoaded<T>(execution: () => Promise<T>): Promise<T> {
        if (this.isReady) {
            return execution();
        }

        return new Promise<T>((resolve, reject) => {
            let subscription = this.settingsLoaded$.subscribe(x => {
                execution()
                    .then(resp => {
                        resolve(resp);
                        subscription.unsubscribe();
                    })
                    .catch(_ => {
                        subscription.unsubscribe();
                        reject();
                    });
            });
        });
    }
}
