<button mat-raised-button [matMenuTriggerFor]="appMenu">
    {{selectedFilter?.DisplayName || 'Выберите'}}
</button>
<mat-menu #appMenu="matMenu">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="onSelectNode(node)">
            <button mat-icon-button disabled></button>
            {{node.item.DisplayName}}
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            {{node.item.DisplayName}}
            <mat-progress-bar *ngIf="node.isLoading"
                              mode="indeterminate"
                              class="example-tree-progress-bar"></mat-progress-bar>
        </mat-tree-node>
    </mat-tree>
</mat-menu>
