import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { SnackBarCallComponent } from "./snackbar-call/snackbar-call.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatSnackBarModule,
    ],
    exports: [
        SnackBarCallComponent,
    ],
    declarations: [
        SnackBarCallComponent
    ],
    providers: []
})
export class SnakBarModule { }
