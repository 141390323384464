import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';

import { FilterComponent } from './filter';
import { FilterTreeComponent } from './tree/filter-tree.component';

@NgModule({
    imports: [
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatTreeModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,

        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        FilterComponent,
        FilterTreeComponent,
    ],
    declarations: [
        FilterComponent,
        FilterTreeComponent,
    ]
})
export class FilterModule { }
