import { Component, ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import * as ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";

import { SignalRService } from '../../shared/core/signalr.service';
import { SnackBarCallComponent } from '../../shared/snackbar/snackbar-call/snackbar-call.component';
import { ConfigurationService } from '../../shared/core/configuration.service';

@Component({
    selector: 'xrm-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss', '../../style/common.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    callList: { [key: string]: MatSnackBarRef<SnackBarCallComponent> } = {};

    constructor(private signalRService: SignalRService,
        private configurationService: ConfigurationService,
        private snackBar: MatSnackBar) {

        configurationService.load();

        signalRService.newCall.subscribe(phone => {
            this.onNewCall(phone);
        });

        signalRService.completedCall.subscribe(phone => {
            this.onCompletedCall(phone);
        });

        // dx localization
        loadMessages(ruMessages);
        locale("ru-RU");
    }

    private onNewCall(phone: string): void {
        let ref = this.snackBar.openFromComponent(SnackBarCallComponent, {
            data: phone,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
        });
        this.callList[phone] = ref;
    }

    private onCompletedCall(phone: string): void {
        setTimeout(() => {
            let ref = this.callList[phone];
            if (ref) {
                ref.dismiss();
                delete this.callList[phone];
            }
        }, 5000);
    }
}
