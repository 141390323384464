import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import * as _moment from 'moment';
import { DetailInfo } from "../core/base-model";
import { ValueAccessorBase } from "./editor-models";

@Component({
    selector: 'date-time',
    templateUrl: './date-time.html',
    styles: [
        ':host { display: flex; }',
        'mat-form-field { width: 100%; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: DateTimeComponent, multi: true },
        DatePipe,
    ],
})
export class DateTimeComponent extends ValueAccessorBase<number | null> {
    @Input() required = false;
    @Input() startDate = new Date();
    @Input() disabled = false;
    @Input() datePlaceholder = '';
    @Input() timePlaceholder = '';

    timeDetails: Promise<DetailInfo[]>;
    date: _moment.Moment | null = null;
    time: DetailInfo | null = null;

    constructor(private datePipe: DatePipe) {
        super();

        this.timeDetails = Promise.resolve(this.getTimeDetails());
    }

    onTimeChanged(): void {
        if (!this.time || !this.date) {
            return;
        }

        const dateTime = this.date.toDate();
        const times = this.time.Id.split(':');
        dateTime.setHours(parseInt(times[0]));
        dateTime.setMinutes(parseInt(times[1]));

        this.value = dateTime.getTime() / 1000;
    }

    writeValue(value: number | null): void {
        super.writeValue(value);
        this.onWriteValue();
    }

    private getTimeDetails(): DetailInfo[] {
        const details: DetailInfo[] = [];
        const times = ['00', '15', '30', '45'];
        for (let i = 0; i < 24; i++) {
            for (const time of times) {
                const time1 = new DetailInfo();
                time1.Id = time1.Name = `${(i + '').padStart(2, '0')}:${time}`;
                details.push(time1);
            }
        }

        if (this.time) {
            const current = this.time.Id.split(':');
            const currentHours = parseInt(current[0]);
            const currentMinutes = parseInt(current[1]);

            for (const detail of details) {
                const time = detail.Id.split(':');
                const hours = parseInt(time[0]);
                const minutes = parseInt(time[1]);

                if (currentHours > hours) {
                    continue;
                }
                else if (currentHours < hours) {
                    const index = details.indexOf(detail);
                    details.splice(index - 1, 0, this.time);
                    break;
                }

                if (currentMinutes > minutes) {
                    continue;
                }
                else if (currentMinutes == minutes) {
                    break;
                }
                else {
                    const index = details.indexOf(detail);
                    details.splice(index - 1, 0, this.time);
                    break;
                }
            }
        }

        if (details.find(m => DetailInfo.equals(m, this.time)) === null)
            details.push(this.time);

        return details;
    }

    private onWriteValue(): void {
        if (this.value) {
            const valueDate = new Date(this.value * 1000);

            const time = this.datePipe.transform(valueDate, 'HH:mm');
            this.date = _moment(new Date(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate()));
            this.time = DetailInfo.create({ Id: time, Name: time });

            this.timeDetails = Promise.resolve(this.getTimeDetails());
        }
        else {
            this.date = null;
            this.time = null;
        }
    }
}
