import { Injectable, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
    private title: string = '';
    public onChange: EventEmitter<string> = new EventEmitter();

    constructor(private titleService: Title) { }

    setTitle(title: string): void {
        this.title = title;
        this.titleService.setTitle(title);
        this.onChange.emit(title);
    }
}
