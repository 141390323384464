import { Component, Input } from "@angular/core";
import { UntypedFormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ValueAccessorBase } from "./editor-models";


@Component({
    selector: 'inline-date',
    templateUrl: './inline-date.html',
    styles: [
        '.editor-text { min-height: 15px;}',
        '.editor-field { width: 100%; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: InlineDateComponent, multi: true }
    ],
})
export class InlineDateComponent extends ValueAccessorBase<number | null> {
    @Input() placeholder: string | null = null;
    @Input() set disabled(value: boolean) {
        value ? this.control.disable() : this.control.enable();
    }
    @Input() errors: string[] = [];
    matcher: ErrorStateMatcher;
    control: UntypedFormControl = new UntypedFormControl();

    constructor() {
        super();
        this.matcher = {
            isErrorState: (control: UntypedFormControl | null): boolean => {
                return this.errors && this.errors.length > 0;
            }
        };

        this.control.valueChanges.subscribe(value => {
            this.value = new Date(value).getTime() / 1000 || null;
        });
        this.control.enable();
    }

    writeValue(value: number | null): void {
        super.writeValue(value);
        this.onWriteValue();
    }

    private onWriteValue(): void {
        let val: any = '';
        if (this.value)
            val = new Date(this.value * 1000);
        this.control.setValue(val, {
            emitEvent: false
        });
    }
}
