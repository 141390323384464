<mat-form-field class="example-chip-list" appearance="fill">
    <mat-label>{{placeholder}}</mat-label>
    <mat-chip-list #chipGrid>
        <mat-chip *ngFor="let label of innerValue" (removed)="remove(label)">
            {{label}}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-list>
    <input  #chipInput
           [formControl]="control"
           [matChipInputFor]="chipGrid"
           [matAutocomplete]="auto"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="add($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
        <mat-option *ngFor="let label of details | async" [value]="label">
            {{label.Name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
