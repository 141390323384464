import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

import { ValueAccessorBase } from "./editor-models";

@Component({
    selector: 'multyline-text',
    templateUrl: './multyline-text.html',
    styles: [
        '.editor-text { min-height: 15px;}',
        '.editor-field { width: 100%; }',
        '.editor-text.disabled { color: rgba(0,0,0,.42); }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: MultylineTextComponent, multi: true }
    ],
})
export class MultylineTextComponent extends ValueAccessorBase<string> {
    @ViewChild('input') input: ElementRef | null = null;

    @Input() placeholder: string | null = null;
    @Input() disabled = false;
    @Input() errors: string[] = [];
    @Input() required = false;
    matcher: ErrorStateMatcher;

    constructor() {
        super();
        this.matcher = {
            isErrorState: (control: UntypedFormControl): boolean => {
                return this.errors && this.errors.length > 0;
            }
        };
    }
}
