import { Component, NgModule, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule, UntypedFormControl, Validators, ReactiveFormsModule, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

import { ProfileService } from './login-service';
import { ModelStateError } from '../../shared/core/http-service';
import { SecurityService } from '../../shared/core/security.service';
import { TitleService } from '../../shared/core/title-service';
import { LoginViewModel } from '../../shared/core/models/login.model';

@Component({
    selector: 'xrm-login',
    templateUrl: './component-login.html',
    styleUrls: ['./component-login.scss']
})
export class ComponentLogin implements OnInit {
    model: LoginViewModel = new LoginViewModel();
    modelState: ModelStateError | null = null;
    loading: boolean = false;
    errorMatcher: ErrorStateMatcher;

    constructor(private profileService: ProfileService,
        private titleService: TitleService,
        private securityService: SecurityService,
        private router: Router) {

        this.errorMatcher = {
            isErrorState: (control: UntypedFormControl): boolean => {
                if (!this.modelState)
                    return false;

                const parent = <UntypedFormGroup>control.parent;
                if (parent) {
                    for (let name in parent.controls) {
                        if (control == parent.controls[name]) {
                            return this.modelState[name] != null;
                        }
                    }
                }
                return false;
            }
        };
    }

    ngOnInit(): void {
        this.titleService.setTitle('Вход');
    }

    onSubmit() {
        this.loading = true;
        this.securityService.Authorize(this.model)
            .then(() => this.router.navigateByUrl('/home'))
            .catch(resp => {
                if (resp instanceof ModelStateError) {
                    this.modelState = resp;
                }
                else {
                    // TODO
                }
            })
            .then(() => this.loading = false);
    }

    stateModelError(name: any): string | null {
        if (!this.modelState || !this.modelState[name])
            return null;
        return this.modelState[name].join(', ');
    }
}

@NgModule({
    imports: [
        MatInputModule,
        MatButtonModule,
        MatCardModule,

        FormsModule,
        ReactiveFormsModule,
        CommonModule
    ],
    declarations: [ComponentLogin],
    exports: [ComponentLogin],
    providers: [ProfileService]
})
export class ComponentLoginModule { }
