import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

import { FlatTreeControl } from "@angular/cdk/tree";
import { CollectionViewer, SelectionChange } from "@angular/cdk/collections";

import { map } from "rxjs/operators";
import { BehaviorSubject, Observable, merge } from "rxjs";

import { FilterTreeService } from "../filter-tree.service";
import { FilterTreeNode, FilterTreeItem } from "../filter.model";

class DynamicDataSource {

    dataChange = new BehaviorSubject<FilterTreeNode[]>([]);

    get data(): FilterTreeNode[] { return this.dataChange.value; }
    set data(value: FilterTreeNode[]) {
        this.treeControl.dataNodes = value;
        this.dataChange.next(value);
    }

    constructor(private treeControl: FlatTreeControl<FilterTreeNode>,
        private database: FilterTreeService) { }

    connect(collectionViewer: CollectionViewer): Observable<FilterTreeNode[]> {
        this.treeControl.expansionModel.changed.subscribe(change => {
            if ((change as SelectionChange<FilterTreeNode>).added ||
                (change as SelectionChange<FilterTreeNode>).removed) {
                this.handleTreeControl(change as SelectionChange<FilterTreeNode>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<FilterTreeNode>) {
        if (change.added) {
            change.added.forEach(node => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
        }
    }

    /**
     * Toggle the node, remove from display list
     */
    toggleNode(node: FilterTreeNode, expand: boolean) {
        this.database.getChildren(node.item.Property)
            .then(children => {
                const index = this.data.indexOf(node);
                if (!children || index < 0) { // If no children, or cannot find the node, no op
                    return;
                }

                node.isLoading = true;

                if (expand) {
                    const nodes = children.map(item =>
                        new FilterTreeNode(item, node.level + 1, this.database.isExpandable(item)));
                    this.data.splice(index + 1, 0, ...nodes);
                } else {
                    let count = 0;
                    for (let i = index + 1; i < this.data.length
                        && this.data[i].level > node.level; i++ , count++) { }
                    this.data.splice(index + 1, count);
                }

                // notify the change
                this.dataChange.next(this.data);
                node.isLoading = false;
            });
    }
}

@Component({
    selector: 'xrm-filter-tree',
    templateUrl: './filter-tree.component.html',
})
export class FilterTreeComponent implements OnInit {
    @Input() selectedFilter: FilterTreeItem;
    @Input() module: string;
    @Output() change: EventEmitter<FilterTreeItem> = new EventEmitter();

    constructor(private database: FilterTreeService) {
        this.treeControl = new FlatTreeControl<FilterTreeNode>(this.getLevel, this.isExpandable);
        this.dataSource = new DynamicDataSource(this.treeControl, database);
    }

    ngOnInit(): void {
        this.database.initialData()
            .then(data => this.dataSource.data = data);
    }

    treeControl: FlatTreeControl<FilterTreeNode>;

    dataSource: DynamicDataSource;

    getLevel = (node: FilterTreeNode) => node.level;

    isExpandable = (node: FilterTreeNode) => node.expandable;

    hasChild = (_: number, _nodeData: FilterTreeNode) => _nodeData.expandable;

    onSelectNode(node: FilterTreeNode): void {
        this.selectedFilter = node.item;
        this.change.emit(this.selectedFilter);
        console.log(this.selectedFilter);
    }
}
