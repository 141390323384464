import { ValueAccessorBase, DateRange } from "./editor-models";
import { Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as _moment from 'moment';

@Component({
    selector: 'date-range',
    templateUrl: './date-range.html',
    styles: [
        ':host { display: flex; }',
        'mat-form-field { width: 100%; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: DateRangeComponent, multi: true },
        DatePipe,
    ],
})
export class DateRangeComponent extends ValueAccessorBase<DateRange> {
    @Input() label = 'Период';

    startInner: Date;
    endInner: Date;

    constructor() {
        super();
        this.value = new DateRange();
    }

    writeValue(value: DateRange): void {
        super.writeValue(value);
        this.onWriteValue();
    }

    private onWriteValue(): void {
        if (this.value) {
            this.startInner = _moment.unix(this.value.start).toDate();
            this.endInner = _moment.unix(this.value.end).toDate();
        }
        else {
            this.startInner = null;
            this.endInner = null;
        }
    }

    onStartChange(event): void {
        const val = event.value;
        if (this.value == null) {
            this.writeValue(new DateRange());
        }

        if (val) {
            this.value.start = val.startOf('day').unix();
        }
        else {
            this.value.start = null;
        }

        this.changed.forEach(f => f(this.value));
        this.onchange.emit(this.value);
    }

    onEndChange(event): void {
        const val = event.value;
        if (this.value == null) {
            this.writeValue(new DateRange());
        }

        if (val) {
            this.value.end = val.endOf('day').unix();
        }
        else {
            this.value.end = null;
        }

        this.changed.forEach(f => f(this.value));
        this.onchange.emit(this.value);
    }

    addNewDate(ev) {
        console.log(ev);
    }
}
