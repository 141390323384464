import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { SignalRService } from '../core/signalr.service';
import { DetailService } from '../core/detail.service';
import { XRM_MODULE } from '../core/constants';
import { DetailHistory } from './history.model';
import { LoadingManager } from '../core/loading-manager';

@Component({
    selector: 'xrm-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
    id: string;
    subscription: Subscription;
    appendSubscription: Subscription;
    history: DetailHistory[] = [];
    displayMore: boolean = false;
    newMessage: string = '';

    loadingManager = new LoadingManager();
    @Output() onloading: EventEmitter<boolean> = this.loadingManager.event;

    constructor(private activatedRoute: ActivatedRoute,
        @Inject(XRM_MODULE) private module: string,
        private signalRService: SignalRService,
        private detailService: DetailService) {
    }

    ngOnInit(): void {
        this.subscription = this.activatedRoute.paramMap.subscribe(() => this.onload());
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.appendSubscription) {
            this.appendSubscription.unsubscribe();
        }
        if (this.id) {
            this.signalRService.unregister([this.id]);
        }
    }

    onload(): void {
        let id = this.activatedRoute.snapshot.paramMap.get('id');
        if (this.id) {
            this.signalRService.unregister([this.id]);
        }
        this.id = id;
        if (this.id) {
            this.signalRService.register([this.id]);
        }
        if (this.appendSubscription) {
            this.appendSubscription.unsubscribe();
        }

        this.signalRService.appendHistoryChanged.subscribe(model => {
            if (model && model.ReferenceId == this.id) {
                this.history.splice(0, 0, model);
            }
        });

        this.loadingManager.generate(this.detailService.saveDetail(this.module, 'GetHistory', this.id, null)
            .then(resp => {
                this.history = resp.Result;
                this.displayMore = resp.ExistsMore;
            }));
    }

    public get permissionAppend(): string {
        return this.module + '.AppendHistory';
    }

    appendHistory(): void {
        if (!this.newMessage)
            return;

        this.loadingManager.generate(this.detailService.saveDetail(this.module, 'AppendHistory', this.id, { Message: this.newMessage })
            .then(() => {
                this.newMessage = '';
            }));
    }

    showMore(): void {
        let lastId = this.history[this.history.length - 1].Id;
        this.loadingManager.generate(this.detailService.saveDetail(this.module, 'GetHistory', this.id, lastId)
            .then(resp => {
                this.history = [].concat(this.history, resp.Result);
                this.displayMore = resp.ExistsMore;
            }));
    }
}
