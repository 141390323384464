import { Injectable, EventEmitter } from '@angular/core';

import { HttpService } from '../core/http-service';
import { SecurityService } from '../core/security.service';
import { SignalRService } from '../core/signalr.service';
import { DetailInfo } from '../core/base-model';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private accountPermission: AccountPermission | null = null;
    private states = new Map<string, boolean>();
    private loaded: Promise<void>;
    public changed: EventEmitter<void> = new EventEmitter();

    constructor(private httpService: HttpService,
        private securityService: SecurityService,
        private signalrService: SignalRService) {
        this.loadModules();
        this.securityService.authenticationChallenge$.subscribe(m => this.loadModules());
        this.signalrService.permissionChanged.subscribe(() => this.loadModules());
    }

    loadModules(): Promise<void> {
        this.loaded = this.httpService.post<AccountPermission>('/api/Module/Load', null)
            .then(resp => {
                this.setPermissions(resp);
            }, () => {
                this.clearState();
            });
        return this.loaded;
    }

    isInState(state: string): boolean {
        return this.states.has(state);
    }

    setPermissions(permission: AccountPermission): void {
        this.accountPermission = permission;
        if (this.accountPermission) {
            this.signalrService.register([this.accountPermission.Account.Id]);
        }
        this.updateStates();
        this.changed.emit();

        // register for messaging
        if (this.states.has('chat.list')) {
            this.signalrService.register(this.accountPermission.Stores);
        }
    }

    getModules(): Promise<ModuleItem[]> {
        return this.loaded.then(() => this.accountPermission ? this.accountPermission.Modules : []);
    }

    isAuthenticated(): Promise<boolean> {
        return this.loaded.then(() => {
            if (this.accountPermission == null)
                return false;

            return this.accountPermission.IsAuthenticated;
        }).catch(() => false);
    }

    getAccount(): DetailInfo {
        return DetailInfo.create(this.accountPermission.Account);
    }

    private clearState(): void {
        this.accountPermission = null;
        this.states = new Map<string, boolean>();
    }

    private updateStates(): void {
        this.states = new Map<string, boolean>();
        if (this.accountPermission != null) {
            console.log(this.accountPermission);
            for (let perm of this.accountPermission.States) {
                this.states.set(perm, true);
            }
        }
    }
}

export class ModuleItem {
    Name: string | null = null;
    Id: string | null = null;
    Url: string | null = null;
}

export class AccountPermission {
    Account: DetailInfo;
    States: string[] = [];
    Modules: ModuleItem[] | null = null;
    IsAuthenticated: boolean = false;
    Stores: string[] = [];
}
