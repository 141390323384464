import { ControlValueAccessor } from "@angular/forms";
import { Output, EventEmitter, Input, Directive } from "@angular/core";

export class DateRange {
    start: number;
    end: number;
}

@Directive()
export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
    @Output('onchange') onchange: EventEmitter<T> = new EventEmitter();

    protected innerValue: T | null = null;

    protected changed = new Array<(value: T) => void>();
    protected touched = new Array<() => void>();

    get value(): T {
        return <T>this.innerValue;
    }

    @Input() set value(value: T) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.changed.forEach(f => f(value));
            this.onchange.emit(value);
        }
    }

    touch() {
        this.touched.forEach(f => f());
    }

    writeValue(value: T) {
        this.innerValue = value;
    }

    registerOnChange(fn: (value: T) => void) {
        this.changed.push(fn);
    }

    registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }
}
