import { Component, NgModule, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TitleService } from '../../shared/core/title-service';
import { SecurityService } from '../../shared/core/security.service';

@Component({
    templateUrl: './component-home.html'
})
export class ComponentHome implements OnInit {
    constructor(public titleService: TitleService,
        router: Router,
        securityService: SecurityService) {
        if (!securityService.IsAuthorized) {
            router.navigateByUrl('/login');
        }
    }

    ngOnInit(): void {
        this.titleService.setTitle('Домашняя страница');
    }
}

@NgModule({
    declarations: [ComponentHome],
    exports: [ComponentHome]
})
export class ComponentHomeModule { }
