import { EventEmitter, Injectable } from "@angular/core";

const SpeechRecognition = window['SpeechRecognition'] || window['webkitSpeechRecognition'];

@Injectable({ providedIn: 'root' })
export class SpeechRecognisionService {
    private recognition: any;
    onRecognize: EventEmitter<string> = new EventEmitter();
    
    running = false;
    enabled = false;

    constructor() {
        if (SpeechRecognition) {
            this.recognition = new SpeechRecognition();

            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.lang = 'ru-RU';

            this.recognition.onresult = (e) => this.onResult(e);
            this.recognition.onend = () => this.onEnd();

            this.enabled = true;
        }
    }

    private onEnd() {
        this.running = false;
    }

    private onResult(event: any) {
        for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
                const final = event.results[i][0].transcript;

                console.log('final', final);

                this.onRecognize.emit(final);
                return;
            }
        }
    }

    start() {
        if (this.running || !this.recognition)
            return;

        this.running = true;
        this.recognition.start();
    }

    stop() {
        if (!this.running || !this.recognition)
            return;

        this.running = false;
        this.recognition.stop();
    }
}
