export class FilterTreeItem {
    Property: string;
    DisplayName: string;
    Operations: number[];
    Type: number;
}

export class FilterTreeNode {
    constructor(public item: FilterTreeItem,
        public level = 1,
        public expandable = false,
        public isLoading = false) { }
}


export class FilterItem {
    Property: string;
    DisplayName: string;
    Operation: number;
    ValueCount: number;
    Value: string;
    DisplayValue: string;
}
