<form (ngSubmit)="onSubmit()" #loginForm="ngForm" class="login-card">
    <mat-card>
        <mat-card-header>
            <mat-card-title><h1>Вход</h1></mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div>
                <mat-form-field>
                    <input [(ngModel)]="model.Login" [errorStateMatcher]="errorMatcher" name="Login" matInput autocomplete="off" placeholder="Пользователь" />
                    <mat-error>{{stateModelError('Login')}}</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input [(ngModel)]="model.Password" [errorStateMatcher]="errorMatcher" name="Password" matInput type="password" autocomplete="off" placeholder="Пароль" />
                    <mat-error>{{stateModelError('Password')}}</mat-error>
                </mat-form-field>
            </div>

        </mat-card-content>
        <mat-card-actions align="end">
            <button [disabled]="!loginForm.form.valid || loading" mat-raised-button color="primary">Войти</button>
        </mat-card-actions>
    </mat-card>
</form>
