import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { SecurityService } from './security.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class HttpService {
    constructor(private http: HttpClient,
        private securityService: SecurityService,
        private configurationService: ConfigurationService) { }

    post<T>(url: string, model: any, params?: { [param: string]: string | string[] }): Promise<T> {
        let body = JSON.stringify(model);

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            })
        };

        return Promise.all([this.securityService.GetToken(), this.configurationService.getSettings()])
            .then((value) => {
                const token = value[0];
                const postUrl = value[1].PersonalUrl + url;
                options.headers = options.headers.append('Authorization', 'Bearer ' + token);
                return this.http.post(postUrl, body, options)
                    .toPromise()
            })
            .catch(resp => this.processError(resp));
    }

    get<T>(url: string, params?: { [param: string]: string | string[] }): Promise<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }),
            params: params
        };

        return this.securityService.GetToken()
            .then(token => {
                options.headers = options.headers.append('Authorization', 'Bearer ' + token);
                return this.http.get(url, options)
                    .toPromise();
            })
            .catch(this.processError);
    }

    upload(url: string, formData: FormData): Promise<any> {
        const options = {
            headers: new HttpHeaders({
                'Accept': 'multipart/form-data'
            }),
        };

        return Promise.all([this.securityService.GetToken(), this.configurationService.getSettings()])
            .then((value) => {
                const token = value[0];
                const postUrl = value[1].PersonalUrl + url;
                options.headers = options.headers.append('Authorization', 'Bearer ' + token);
                return this.http.post(postUrl, formData, options)
                    .toPromise()
                    .catch();
            })
            .catch(resp => this.processError(resp));
    }

    private processError(error: HttpErrorResponse): Promise<any> {
        if (error && error.status === 400) {
            return Promise.reject(new ModelStateError(error.error));
        }
        return Promise.reject(null);
    }
}

export class ModelStateError {
    constructor(state?: ModelStateError) {
        if (state) {
            for (let key in state) {
                this[key] = state[key];
            }
        }
    }

    [key: string]: string[];
}
