import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DxDataGridModule, DxTreeListModule } from "devextreme-angular";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { EditorModule } from "../editor/editor-module";
import { FilterModule } from "../filter/filter.module";

@NgModule({
    exports: [
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        MatTableModule,
        MatRadioModule,
        MatDatepickerModule,
        MatToolbarModule,
        MatSelectModule,
        MatMenuModule,
        MatChipsModule,

        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,

        DxDataGridModule,
        DxTreeListModule,

        EditorModule,
        FilterModule,
    ],
})
export class BaseModule { }
