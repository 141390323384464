<div *permissionIf="permissionAppend">
    <multyline-text placeholder="Комментарий" [(ngModel)]="newMessage"></multyline-text>
    <button mat-raised-button [disabled]="!newMessage" (click)="appendHistory()">Добавить</button>
</div>
<div *ngFor="let item of history">
    <div class="message" [ngClass]="{ 'system': item.IsSystem }">{{item.Message}}</div>
    <div class="sub-message">
        {{item.CreatedBy}} в {{item.CreatedOn * 1000 | date: 'HH:mm dd.MM.yyyy'}}
    </div>
    <mat-divider></mat-divider>
</div>
<button *ngIf="displayMore" mat-raised-button (click)="showMore()">Показать ещё</button>
