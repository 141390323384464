import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ValueAccessorBase } from "./editor-models";

@Component({
    selector: 'inline-number',
    templateUrl: './inline-number.html',
    styles: [
        ':host { width: 100%; }',
        '.editor-text { min-height: 1.125em;}',
        '.editor-field ::ng-deep .mat-form-field-infix { width: 60px }',
        '.editor-field { width: 100%; }'
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: InlineNumberComponent, multi: true }
    ],
})
export class InlineNumberComponent extends ValueAccessorBase<number> {
    @Input() disabled = false;
    @Input() placeholder: string | null = null;
    @Input() required = false;
    @Input() errors: string[] = [];
    matcher: ErrorStateMatcher;

    @ViewChild('input') input: ElementRef | null = null;

    constructor() {
        super();
        this.matcher = {
            isErrorState: (control: UntypedFormControl | null): boolean => {
                return this.errors && this.errors.length > 0;
            }
        };
    }

    focus() {
        if (!this.disabled) {
            this.input.nativeElement.focus();
        }
    }
}
