<div>Входящий звонок: {{phone}}</div>
<ng-template [ngIf]="contacts && contacts.length">
    <div>Контакты:</div>
    <div *ngFor="let contact of contacts">
        <a [routerLink]="['/contact', 'details', contact.ContactId]">{{contact.FIO}}</a>
    </div>
</ng-template>
<ng-template [ngIf]="!contacts || !contacts.length">
    <a [routerLink]="['/contact', 'create', { phone: phone }]">Создать контакт</a>
</ng-template>
<div>
    <a [routerLink]="['/sale', 'create', { phone: phone }]">Создать заказ</a>
</div>
<div *ngIf="leads && leads.length">Лиды:</div>
<div *ngFor="let lead of leads">
    <a [routerLink]="['/lead', 'details', lead.LeadId]">{{lead.Name || 'Лид'}}</a>
</div>
