import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";

const DetailExpandKey = 'DetailExpanded';

@Injectable()
export class DetailExpandService {
    keys: { [key: string]: boolean };

    constructor(private storageService: StorageService) {
        this.retrieve();
    }

    private retrieve() {
        this.keys = this.storageService.retrieve(DetailExpandKey) ?? {};
    }

    set(key: string, expanded: boolean) {
        if (!key)
            return;

        this.retrieve();
        this.keys[key] = expanded;
        this.storageService.store(DetailExpandKey, this.keys);
    }

    get(key: string): boolean {
        if (!key)
            return false;

        return this.keys[key] ?? false;
    }
}
