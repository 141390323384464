import { HubConnectionBuilder, LogLevel, HubConnection, HttpTransportType } from '@microsoft/signalr';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationService } from './configuration.service';
import { SecurityService } from './security.service';

@Injectable({ providedIn: 'root' })
export class SignalRService {
    private connection: HubConnection = null;
    private isStoped = false;
    private isConnected = false;
    newCall: EventEmitter<string> = new EventEmitter();
    completedCall: EventEmitter<string> = new EventEmitter();
    detailChanged: EventEmitter<string> = new EventEmitter();
    listChanged: EventEmitter<string> = new EventEmitter();
    permissionChanged: EventEmitter<void> = new EventEmitter();
    locationChanged: EventEmitter<void> = new EventEmitter();
    appendHistoryChanged: EventEmitter<any> = new EventEmitter();
    newMessages: EventEmitter<string> = new EventEmitter();

    groups: string[] = [];

    constructor(private configurationService: ConfigurationService,
        securityService: SecurityService,
        private router: Router) {

        securityService.authenticationChallenge$.subscribe(resp => {
            if (resp) {
                this.isStoped = false;
                this.onStart();
                Notification.requestPermission();
            }
            else {
                this.onStop();
            }
        });

        if (securityService.IsAuthorized) {
            this.isStoped = false;
            this.onStart();
            Notification.requestPermission();
        }
    }

    private onStart(): void {
        this.configurationService.executeLoaded(() => this.initStart());
    }

    private onStop(): void {
        if (this.connection) {
            this.isStoped = true;
            this.connection.stop();
        }
    }

    private initStart(): Promise<void> {
        const url = this.getHubUrl();

        if (this.connection === null) {
            this.connection = new HubConnectionBuilder()
                .withUrl(url, HttpTransportType.WebSockets | HttpTransportType.LongPolling | HttpTransportType.ServerSentEvents)
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: _ => {
                        if (this.isStoped)
                            return null;
                        return Math.random() * 10_000;
                    }
                })
                .build();

            this.initEvents();
        }

        this.isConnected = false;
        return this.connection
            .start()
            .then(() => {
                this.isConnected = true;
                this.register(this.groups);
            })
            .catch(err => {
                this.isConnected = false;
                if (this.isStoped)
                    return;

                console.error(err.toString());
            });
    }

    private getHubUrl(): string {
        return this.configurationService.serverSettings.HubUrl;
    }

    private initEvents() {
        this.connection.on("NewCall", (phone: string) => {
            this.newCall.emit(phone);
        });
        this.connection.on("CompletedCall", (phone: string) => {
            this.completedCall.emit(phone);
        });

        this.connection.on("DetailChanged", (id: string) => {
            this.detailChanged.emit(id);
        });

        this.connection.on("NewSale", (saleId: string, number: number) => {
            const notification = new Notification(`Создан новый заказ №${number}!`);

            notification.onclick = () => {
                this.router.navigate(['/sale', 'details', saleId]);
                window.focus();
                notification.close();
            };

            /*const audio = new Audio();
            audio.src = "/sound/order-alert.oga";
            audio.load();
            audio.play();*/
        });

        this.connection.on("PermissionChanged", () => {
            this.permissionChanged.emit();
        });

        this.connection.on("LocationChanged", () => {
            this.locationChanged.emit();
        });

        this.connection.on("appendHistory", model => {
            this.appendHistoryChanged.emit(model);
        });

        this.connection.on("ListChanged", model => {
            this.listChanged.emit(model);
        });

        this.connection.on('NewMessage', model => {
            this.newMessages.emit(model);
        });
    }

    public register(groups: string[]): void {
        if (groups) {
            const list = this.groups.concat(groups);
            this.groups = list.filter((val, index) => list.indexOf(val) === index);
        }
        if (this.isConnected && this.connection) {
            this.connection.invoke("Register", groups);
        }
    }

    public unregister(groups: string[]): void {
        if (groups) {
            this.groups = this.groups.filter(val => groups.indexOf(val) === -1);
        }
        if (this.isConnected && this.connection) {
            this.connection.invoke("Unregister", groups);
        }
    }
}
