import { Component, Input, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ErrorStateMatcher } from "@angular/material/core";

import { debounceTime } from 'rxjs/operators';

import { ValueAccessorBase } from "./editor-models";
import { DetailInfo } from "../core/base-model";
import { AutocompleteFn } from "../core/common-helper";

@Component({
    selector: 'autocomplete',
    templateUrl: './autocomplete.html',
    styles: ['.editor-field { width: 100%; }'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: AutocompleteComponent, multi: true }
    ],
})
export class AutocompleteComponent extends ValueAccessorBase<DetailInfo> implements OnInit {
    @Input() details: Promise<DetailInfo[]> | null = null;
    @Input() placeholder: string = '';
    @Input() fn: AutocompleteFn | null = null;
    control: UntypedFormControl = new UntypedFormControl();
    @Input() errors: string[] = [];
    @Input() required = false;
    matcher: ErrorStateMatcher;

    @Input() suffixIcon: string;
    @Input() suffixAction: Function;

    constructor() {
        super();
        this.matcher = {
            isErrorState: (control: UntypedFormControl | null): boolean => {
                return this.errors && this.errors.length > 0;
            }
        };
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            debounceTime(500)
        )
            .subscribe(value => {
                if (typeof (value) == "string") {
                    this.updateDetails(value);
                }
            });
        this.updateDetails(null);
    }

    private updateDetails(query: string | null) {
        if (this.fn)
            this.details = this.fn(query || '');
    }

    onBlur(): void {
        this.control.setValue(this.value);
    }

    displayFn(detail: DetailInfo): string {
        return detail ? detail.Name : '';
    }

    optionSelected(e: MatAutocompleteSelectedEvent): void {
        this.value = e.option.value;
    }

    execSuffixAction(): void {
        if (this.suffixAction) {
            this.suffixAction();
        }
    }
}
