import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

import { ValueAccessorBase } from "./editor-models";

@Component({
    selector: 'inline-text',
    templateUrl: './inline-text.html',
    styles: [
        ':host { width: 100%; }',
        '.editor-text { min-height: 15px; word-break: break-word; }',
        '.editor-field { width: 100%; }',
        '.editor-text.disabled { color: rgba(0,0,0,.42); }',
        'mat-icon { cursor: pointer; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: InlineTextComponent, multi: true }
    ],
})
export class InlineTextComponent extends ValueAccessorBase<string> {
    @ViewChild('input') input: ElementRef | null = null;

    @Input() placeholder: string | null = null;
    @Input() disabled = false;
    @Input() errors: string[] = [];
    @Input() required = false;
    @Input() detailUrl = '';
    @Input() suffixIcon: string;
    @Input() suffixAction: Function;
    @Input() trimOnPaste = false;
    matcher: ErrorStateMatcher;

    constructor() {
        super();
        this.matcher = {
            isErrorState: (control: UntypedFormControl | null): boolean => {
                return this.errors && this.errors.length > 0;
            }
        };
    }

    onClick(): void {
        if (!this.disabled) {
            if (this.input)
                this.input.nativeElement.focus();
        }
    }

    execSuffixAction(): void {
        if (this.suffixAction) {
            this.suffixAction();
        }
    }

    onPaste(e: any): void {
        if (!this.trimOnPaste)
            return;

        this.value = (e.clipboardData.getData("Text") || "").trim();
        console.log(JSON.stringify(this.value));
        e.preventDefault();
    }

    focus() {
        this.onClick();
    }
}
