import { EventEmitter } from "@angular/core";

export class LoadingManager {
    event: EventEmitter<boolean> = new EventEmitter();
    private counter = 0;

    generate<T>(promise: Promise<T>): Promise<T> {
        this.loading();
        promise.finally(() => this.loaded());
        return promise;
    }

    loading() {
        if (this.counter === 0)
            this.event.emit(true);

        this.counter++;        
    }

    loaded() {
        this.counter--;
        if (this.counter === 0)
            this.event.emit(false);
    }

    get isLoading(): boolean {
        return this.counter !== 0; 
    }
}
