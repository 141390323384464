import { Component, NgModule } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { DetailInfo } from '../core/base-model';
import { BaseModule } from '../core/base.module';
import { SecurityService } from '../core/security.service';
import { SpeechRecognisionService } from '../core/speech.service';
import { TitleService } from '../core/title-service';
import { PermissionService } from '../permissions/permissions';
import { ChangePasswordComponent } from './change-password';

@Component({
    selector: 'xrm-navbar',
    templateUrl: './navbar.html',
    styleUrls: ['navbar.scss']
})
export class NavBar {
    title: string;
    IsAuthorized: boolean = false;
    account: DetailInfo = null;

    constructor(private titleService: TitleService,
        private securityService: SecurityService,
        private speechRecognisionService: SpeechRecognisionService,
        private dialog: MatDialog,
        private router: Router,
        private permissionService: PermissionService) {
        this.title = '';
        this.titleService.onChange.subscribe((t: string) => {
            this.title = t;
        });

        this.IsAuthorized = this.securityService.IsAuthorized;
        this.securityService.authenticationChallenge$
            .subscribe(m => this.IsAuthorized = m);
        permissionService.changed.subscribe(() => this.updateUser());

        this.speechRecognisionService.onRecognize.subscribe(val => this.onRecognize(val));
    }

    logout(): void {
        this.securityService.Logoff();
    }

    updateUser(): void {
        this.account = this.permissionService.getAccount();
    }

    changePassword(): void {
        this.dialog.open(ChangePasswordComponent, {
            role: 'alertdialog',
            disableClose: true
        });
    }

    // speech recognise
    onRecognize(val: string) {
        if (!val)
            return;

        val = val.trim().toLowerCase();
        switch (val) {
            case 'заказы':
                this.router.navigate(['/sale']);
                break;
            case 'создание заказа':
                this.router.navigate(['/sale', 'create']);
                break;
        }
    }

    // speech display
    get enableSpeech(): boolean {
        return this.speechRecognisionService.enabled;
    }

    get runningSpeech(): boolean {
        return this.speechRecognisionService.running;
    }

    startSpeech() {
        this.speechRecognisionService.start();
    }

    stopSpeech() {
        this.speechRecognisionService.stop();
    }
}

@NgModule({
    imports: [
        MatDialogModule,
        BaseModule,
        RouterModule,
    ],
    exports: [NavBar],
    declarations: [NavBar, ChangePasswordComponent]
})
export class NavBarModule { }
