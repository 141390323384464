import { Component, Input, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { XRM_MODULE } from '../core/constants';
import { HttpService } from '../core/http-service';
import { FilterTreeService } from './filter-tree.service';
import { FilterTreeItem, FilterItem } from './filter.model';
import { DetailInfoT } from '../core/base-model';
import { EOperation } from '../core/grid-service';
import { Moment } from 'moment';

@Component({
    selector: 'xrm-filter',
    templateUrl: './filter.html',
    styleUrls: ['./filter.scss'],
    providers: [FilterTreeService]
})
export class FilterComponent implements OnInit {
    @Input() query: string;
    @Input() orderBy: string;
    @Output() filterChanged: EventEmitter<void> = new EventEmitter();

    public filters: FilterItem[] = [];
    editFilter: FilterItem | null = null;

    constructor(private httpService: HttpService,
        private db: FilterTreeService,
        @Inject(XRM_MODULE) public module: string) {
    }

    ngOnInit(): void {
        this.loadSavedFilters();
    }

    // helpers
    operationValueCount(operation: number | null): number {
        switch (operation) {
            case EOperation.Like:
            case EOperation.Contains:
            case EOperation.Lt:
            case EOperation.Gt:
            case EOperation.NotEqual:
            case EOperation.LtOrEqual:
            case EOperation.GtOrEqual:
            case EOperation.Equal:
                return 1;
            case EOperation.IsEmpty:
            case EOperation.IsNotEmpty:
                //case EOperation.ISNULL:
                //case EOperation.ISNOTNULL:
                return 0;
        }
    }

    operationToName(operation): string {
        switch (operation) {
            case EOperation.Like: return 'Как';
            case EOperation.Contains: return 'Содержит';
            case EOperation.IsEmpty: return 'Пустой';
            case EOperation.IsNotEmpty: return 'Не пустой';
            //case EOperation.ISNULL: return 'Null';
            //case EOperation.ISNOTNULL: return 'NotNull';
            case EOperation.Lt: return 'Меньше';
            case EOperation.Gt: return 'Больше';
            case EOperation.NotEqual: return 'Не равен';
            case EOperation.LtOrEqual: return 'Меньше или равен';
            case EOperation.GtOrEqual: return 'Больше или равен';
            case EOperation.Equal: return 'Равен';
        }
        return operation;
    }

    calcPossibleOperations() {
        let filter = this.selectedFilter;

        if (!filter) {
            this.possibleOperations = [];
            return;
        }

        this.possibleOperations = filter.Operations.map(op => DetailInfoT.create({ Id: op, Name: this.operationToName(op) }));
    };

    calcFieldType() {
        let filter = this.selectedFilter;

        if (filter) {
            this.fieldType = filter.Type;
            return;
        }

        this.fieldType = null;
    }

    // arrays
    possibleOperations: DetailInfoT<number>[] = [];

    displayAddFilter = false;

    // init filter add
    selectedFilter: FilterTreeItem | null = null;
    selectedOperation: number | null = null;

    fieldType: number | null = null;
    fieldValue = null;

    // functions

    getOperationName = function (operation) {
        return this.operationToName(operation);
    };

    showInput(): boolean {
        return this.operationValueCount(this.selectedOperation) == 1;
    };

    showAddFilter() {
        this.displayAddFilter = true;
    };
    hideAddFilter() {
        this.editFilter = null;
        this.displayAddFilter = false;
    };

    // apply / remove
    applyAddFilter() {
        let type = this.fieldType,
            value = this.fieldValue,
            filter = this.selectedFilter,
            operation = this.selectedOperation;

        // validate value
        if (!operation || !type || (this.showInput() && !value))
            return;

        let displayValue = null;
        if (type === 4) {
            let date = new Date(this.fieldValue * 1000);
            displayValue = date.toLocaleDateString();
        }

        let newFilter: FilterItem = {
            Property: filter.Property,
            Value: value,
            DisplayValue: displayValue,
            Operation: operation,
            ValueCount: this.operationValueCount(operation),
            DisplayName: filter.DisplayName,
        };

        if (this.editFilter) {
            let index = this.filters.indexOf(this.editFilter);
            if (index != -1) {
                this.filters.splice(index, 1, newFilter);
            }
        }
        else
            this.filters.push(newFilter);

        this.filterChanged.emit();
        this.hideAddFilter();
    };

    removeFilter(filter) {
        let filters = this.filters,
            index = filters.indexOf(filter);
        if (index != -1) {
            filters.splice(index, 1);
        }

        this.filterChanged.emit();
    };

    refreshList() {
        this.filterChanged.emit();
    }

    displayOperation(filter: FilterItem): void {
        if (!filter)
            return;

        this.db.get(filter.Property)
            .then(treeItem => {
                this.displayAddFilter = true;
                this.selectedFilter = treeItem;

                this.fieldType = treeItem.Type;
                this.selectedOperation = filter.Operation;
                this.editFilter = filter;
                // set value
                this.fieldValue = filter.Value;
            });
    };

    displayValue(filter: FilterItem): string {
        return filter.DisplayValue || filter.Value;
    }

    // saved filters
    savedFilters = [];
    displaySavedFilters = false;
    getSavedFilterOptions() {
        return {
            dataSource: this.savedFilters,
            dataTextField: 'name',
            dataValueField: 'value'
        };
    }

    saveFilters() {
        //let name = this.savedFilterElement.text(),
        //    finded = false,
        //    value = { name: name, value: angular.copy(this.filters) },
        //    filters = this.savedFilters;

        //for (let i = 0; i < filters.length; i++) {
        //    if (filters[i].name === name) {
        //        finded = true;
        //        filters[i].value = value.value;
        //        break;
        //    }
        //}

        //if (!finded)
        //    this.savedFilters.push(value);
        //this.updateSavedFilters();
    }

    savedFilterSelected = false;
    savedFilterChanged(e) {
        let sender = e.sender,
            node = e.node,
            item = sender.dataItem(node);

        if (item) {
            //this.filters = angular.copy(item.value);
            this.filterChanged.emit();
            this.savedFilterSelected = true;
        }
        else {
            this.savedFilterSelected = false;
        }
    }

    deleteCurrentSavedFilter() {
        //let name = this.savedFilterElement.text(),
        //    filters = this.savedFilters;
        //for (let i = 0; i < filters.length; i++) {
        //    if (filters[i].name === name) {
        //        filters.splice(i, 1);
        //        this.updateSavedFilters();
        //        break;
        //    }
        //}
    }

    loadSavedFilters() {
        //this.httpService
        //    .post(`/api/${this.module}/Filters`, { id: null })
        //    .then((data: []) => {
        //        this.savedFilters = data;
        //        this.displaySavedFilters = data.length > 0;
        //        this.updateSavedFiltersDataSource();
        //    });
    }

    updateSavedFiltersDataSource() {
        this.displaySavedFilters = false;
        //if (this.savedFilterElement) {
        //    this.savedFilterElement.dataSource.data(this.savedFilters);
        //    this.displaySavedFilters = this.savedFilters.length > 0;
        //}
    }

    updateSavedFilters() {
        let data = this.savedFilters;
        this.updateSavedFiltersDataSource();
        let value = JSON.stringify(data);
        //UserFilterService.get({ name: 'UpdateFilters' }, { module: this.module, value: value }, function () { });
    }

    onSelectFilter(filter: FilterTreeItem): void {
        this.selectedFilter = filter;
        this.calcPossibleOperations();
        this.calcFieldType();
    }

    onDatechange(e: MatDatepickerInputEvent<Moment>): void {
        let value = e.value;
        this.fieldValue = null;
        if (value) {
            this.fieldValue = value.unix();
        }
    }
}
