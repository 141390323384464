import { Injectable, Inject } from "@angular/core";

import { HttpService } from "../core/http-service";
import { XRM_MODULE } from "../core/constants";
import { FilterTreeItem, FilterTreeNode } from "./filter.model";

@Injectable()
export class FilterTreeService {
    constructor(private httpService: HttpService,
        @Inject(XRM_MODULE) private module: string) {
    }

    dataMap = new Map<string, FilterTreeItem[]>();

    initialData(): Promise<FilterTreeNode[]> {
        return this.load(null)
            .then(items => items.map(item => new FilterTreeNode(item, 0, this.isExpandable(item))));
    }

    get(property: string): Promise<FilterTreeItem> {
        const properties = property.split('.');
        properties.splice(properties.length - 1, 1);
        const parent = properties.join('.') || null;
        return this.getChildren(parent)
            .then(items => items.filter(m => m.Property == property)[0]);
    }

    getChildren(node: string): Promise<FilterTreeItem[]> {
        return this.load(node);
    }

    isExpandable(node: FilterTreeItem): boolean {
        return node.Type == 0;
    }

    load(property: string): Promise<FilterTreeItem[]> {
        if (this.dataMap.has(property)) {
            return Promise.resolve(this.dataMap.get(property));
        }

        return this.httpService
            .post(`/api/${this.module}/Filters`, { id: property })
            .then((items: FilterTreeItem[]) => {
                this.dataMap.set(property, items);
                return items;
            });
    }
}
