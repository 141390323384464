<div class="head">
    <span class="head__click" (click)="toggleExpand()">
        <ng-content select="[details-head]"></ng-content>
        <button mat-icon-button>
            <mat-icon [ngClass]="{'head-icon': true, 'expanded': expanded}">chevron_right</mat-icon>
        </button>
    </span>
    <ng-content *ngIf="expanded" select="[details-head-add]"></ng-content>
</div>
<div *ngIf="loaded" [class.expanded]="expanded" class="content">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <ng-container *ngTemplateOutlet="detailContentRef"></ng-container>
</div>
