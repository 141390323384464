import { Component, Input, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { ValueAccessorBase } from "./editor-models";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: 'dropdown-string',
    templateUrl: './dropdown-string.html',
    styles: [
        ':host { width: 100%; }',
        '.editor-field { width: 100%; }',
    ],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: DropdownStringComponent, multi: true }
    ],
})
export class DropdownStringComponent extends ValueAccessorBase<string> {
    @ViewChild('input') input: MatSelect;

    @Input() details: Promise<string[]> | null = null;
    @Input() placeholder: string = '';
    @Input() required = true;
    @Input() disabled = false;

    focus() {
        this.input.focus();
    }
}
