import { Directive, TemplateRef, ViewContainerRef, Input, OnDestroy } from "@angular/core";
import { PermissionService } from "./permissions";
import { Subscription } from "rxjs";

@Directive({
    selector: '[permissionIf]'
})
export class PermissionIfDirective implements OnDestroy {
    private permission: string | string[];
    private subscription: Subscription;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService,
    ) {
        this.subscription = this.permissionService.changed.subscribe(() => this.onUpdatePermissions(this.permission));
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    @Input() set permissionIf(permission: string[] | string) {
        this.onUpdatePermissions(permission);
    }

    onUpdatePermissions(permission: string[] | string): void {
        this.permission = permission;
        this.viewContainer.clear();
        if (this.isInState(permission)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    private isInState(permission: string[] | string): boolean {
        if (!permission) {
            return true;
        }

        if (permission instanceof Array)
        {
            const permissions = <string[]>permission;
            for (let perm of permissions) {
                if (this.permissionService.isInState(perm)) {
                    return true;
                }
            }
        }
        else {
            return this.permissionService.isInState(<string>permission);
        }

        return false;
    }
}
