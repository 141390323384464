import { Injectable } from "@angular/core";

import { TitleService } from "./title-service";
import { HttpService } from "./http-service";
import { DetailInfo, SaveModel } from "./base-model";

@Injectable()
export class DetailService {
    constructor(private titleService: TitleService,
        private http: HttpService) { }

    setTitle(title: string): void {
        this.titleService.setTitle(title);
    }

    load<T>(module: string, id: string, type: { new(t: T): T }): Promise<T> {
        return this.http.post<T>(`/api/${module}/Details`, { id: id })
            .then(resp => {
                if (!resp)
                    return null;

                return new type(resp);
            });
    }

    save<T>(module: string, model: SaveModel, type: { new(t: T): T; }): Promise<T> {
        return this.http.post<T>(`/api/${module}/Save`, model)
            .then(resp => {
                if (!resp)
                    return null;

                return new type(resp);
            });
    }

    saveDetail(module: string, detail: string, id: string, model): Promise<any> {
        return this.http.post(`/api/${module}/${detail}/${id}`, model);
    }

    sources(module: string, source: string): Promise<DetailInfo[]> {
        return this.http.post<DetailInfo[]>(`/api/${module}/${source}`, {})
            .then(m => m.map(d => DetailInfo.create(d)));
    }

    details(module: string, source: string, id: string): Promise<any> {
        return this.http.post(`/api/${module}/${source}`, { id: id });
    }

    remove(module: string, id: string): Promise<any> {
        return this.http.post(`/api/${module}/Destroy`, { id: id });
    }

    autocomplete(module: string, method: string, query: string): Promise<DetailInfo[]> {
        return this.http.post(`/api/${module}/${method}`, { query: query });
    }
}
